//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";
import uuid4 from "uuid4";
const trackerIdKey = "xCasaTrackerId";
import ClickOutside from "vue-click-outside";
export default {
  name: "Header",
  props: ['marqueeText'],
  watch: {
    "$store.state.list.search_input"(val) {
      if (val != null && val != "") {
        this.searchFocused = true;
      } else {
        this.searchFocused = false;
      }
    },
    $route() {
      this.userTracker();
    }

  },
  data: function() {
    return {
      searchFocused: false,
      inputFocus: false,
      showLimitedOffer: true,
      activeBoys: true,
      activeGirls: false,
      activeSearchClass: false,
      scrollPosition: null,
      search: false,
      searchshow: true,
      mobileview: false,
    };
  },
  directives: {
    ClickOutside,
  },
  updated() {
    if (this.$route.path === "/thankyou") {
      this.showLimitedOffer = true;
    } else {
      this.showLimitedOffer = false;
    }
  },
  mounted() {
    window.addEventListener("click",this.closeMiniCart)
    window.addEventListener("scroll", this.updateScroll);
    window.addEventListener("scroll",()=>{
      this.hide_keyboard()
    })
  },
  destroy() {
    window.removeEventListener("scroll", this.updateScroll);
    window.removeEventListener("click",this.closeMiniCart)
  },
  beforeMount() {
    if (window.innerWidth < 767) {
      this.mobileview = true;
      this.searchshow = false;
    }

    this.userTracker();

  },
  methods: {
    userTracker() {
      this.getUserTrackerId();
      return (
        config => {
          let customHeaders = getUserTrackerHeaders();
          _.forEach(_.keys(customHeaders), k => {
            config.headers[k] = customHeaders[k];
          });
          return config;
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    getUserTrackerHeaders() {
      return { "X-Casa-Tracker": getUserTrackerId() };
    },
    getUserTrackerId() {
      let trackerId = localStorage.getItem(trackerIdKey);
      if (trackerId) {
        return trackerId;
      } else {
        let newTrackerId = uuid4();
        localStorage.setItem(trackerIdKey, newTrackerId);
        return newTrackerId;
      }
    },
    cart_child(event){
      event.stopPropagation();
    },
    clearInput() {
      this.$store.state.list.search_input = "";
    },
    addCountToreactiveComponent() {
      this.mobile_menu_toggle();
      if (window.innerWidth < 767) {
        return;
      }
      this.$store.state.list.reactiveComponentCount = this.$store.state.list.reactiveComponentCount + 1;
    },
    activeClass() {
      var dflt = "active-boys";
      if (this.activeGirls == true) {
        dflt = "active-girls";
      }
      return dflt;
    },
    checkDepth(item) {
      var depth = false;
      if (item.childs) {
        if (item.childs[0].childs) {
          if (item.childs[0].childs[0].childs) {
            depth = true;
          }
        }
      }

      return depth;
    },
    menuClass(item) {
      // var name = (' ' + item).slice(1);
      item = item.toLowerCase().replace(/ /g, "-");
      item = item.replace(/-$/g, "");
      return item;
    },
    search_empty(){
      $("#searchMobile").val('')
    },
    focusInputSearch() {
      if (this.$store.state.list.search_input != "" && this.$store.state.list.search_input != null) {
        this.searchFocused = true;
      } else {
        this.searchFocused = false;
      }
    },
    toggleSearch() {
      this.activeSearchClass = !this.activeSearchClass;
      if (this.activeSearchClass === true) {
        setTimeout(() => {
          this.$refs.searchControl.focus();
        }, 100);
      }
    },
    updateScroll() {
      // if(this.activeSearchClass === true) {

      //     this.activeSearchClass = false
      // }
      if (this.searchFocused === true && window.innerWidth < 767) {
        this.searchFocused = false;
      }

      this.scrollPosition = window.scrollY;
      //   if(this.scrollPosition < 170){
      //       this.activeSearchClass= false
      //   }
    },
    login_popup_show(e) {
      e.preventDefault();
      this.$store.state.list.login_popup = true;
    },
    close_login_popup() {
      this.$store.state.list.login_popup = false;
    },
    mobile_menu_toggle() {
      event.target.parentElement.classList.toggle("active");
    },
    search_show() {
      if (this.search == true) {
        this.search = false;
      } else {
        this.search = true;
      }
    },
    AvoidSpace(e) {
      var name = /^(?!\s*$).+/;
      if (e.target.value.match(name)) {
        this.$store.state.list.search_input = e.target.value;
      } else {
        this.$store.state.list.search_input = "";
      }
    },
    checkout_gtm: function() {
      this.$gtm.push({
        event: "checkout",
        action: "Checkout",
        ecommerce: {
          checkout: {
            actionField: { step: 1, option: this.$store.state.list.login_status },
            product: this.$store.state.list.gtm_product,
          },
        },
      });
    },
    menu_hide() {
      this.$store.state.list.menuActive = true;
    },
    menu_show() {
      this.$store.state.list.menuActive = false;
      // this.$store.state.list.search_input="";
      
    },
    closeMiniCart(){      
      this.$store.state.list.minicart = true;
    },
    minicart_show() {
      this.$store.state.list.minicart = !this.$store.state.list.minicart;
    },
    minicart_hide() {
      // this.$store.state.list.minicart = true;
    },
    search_close() {
      //  if(this.activeSearchClass === true) {
      //    this.activeSearchClass = false

      //}

      if (window.innerWidth < 767) {
        this.mobileview = false;
        this.searchshow = false;
      }
    },
    mobile_serach() {
      this.searchshow = true;
      this.mobileview = false;
    },
    hide_keyboard(){
document.activeElement.blur();
    },
     triggerBeforeSignOut() {
      let form = new FormData()
      form.append("customer_id", this.$store.state.list.customer_id)
      form.append("customer_session", this.$store.state.list.customer_session)
       axios.post(this.$store.state.list.cart_api_url + "/customer/signout", form)
      .then((response) => {
        console.log(response);
      })
      .catch(error => {
        console.log("error", error);
      })
    },
    log_out:  function() {
      this.triggerBeforeSignOut()
      localStorage.removeItem("customer_phone");
      localStorage.removeItem("admin_login");
      $cookies.set(window.location.hostname.substring(10, 4) + "_customer", "");
      this.$store.state.list.customer_id = "";
      $cookies.set(window.location.hostname.substring(10, 4) + "_cart", "");
      this.$store.state.list.cart_id = "";
      this.$toast.success("You have been successfully logout", {
        position: "top",
        duration: 4000,
      });
      this.$router.push('/');
      setTimeout(() => {
        window.location.reload(true);
      }, 2000);
    },
    deleteEvent: function(index) {
      this.$gtm.push({
        event: "removeFromCart",
        category: "cart",
        action: "Remove From Cart",
        label: name,
        cart: {
          remove: {
            product: [
              {
                name: this.$store.state.list.cart_product[index].name,
                id: this.$store.state.list.cart_product[index].master_sku,
                price: this.$store.state.list.cart_product[index].selling_price,
                category: "",
                variant: this.$store.state.list.cart_product[index].size,
                quantity: this.$store.state.list.cart_product[index].qty,
              },
            ],
          },
        },
      });
      this.$store.state.list.success_message = "";
      this.$store.state.list.error_message = "";
      this.$store.state.list.page_loader = true;
      var form = new FormData();
      form.append("cart_id", this.$store.state.list.cart_id);
      form.append("cart_session", this.$store.state.list.cart_session);
      form.append("product_id", this.$store.state.list.cart_product[index].variant_id);
      axios
        .post(this.$store.state.list.cart_api_url + "/product/remove-product", form)
        .then((response) => {
          if (response.data.success === true) {
            this.$store.state.list.page_loader = false;
            this.$toast.success(response.data.message, {
              position: "top",
              duration: 4000,
            });
            this.$store.state.list.error_message = "";
            this.$store.state.list.cart_total = response.data.data.grand_total;
            this.$store.state.list.cart_product = response.data.data.products;
            this.$store.state.list.discount_code = response.data.data.discount_code;
            this.$store.state.list.discount_amount = response.data.data.discount_amount;
            if (response.data.data.cart_session) {
              $cookies.set(
                window.location.hostname.substring(10, 4) + "_ct_sess",
                response.data.data.cart_session,
                "1y"
              );
              this.$store.state.list.cart_session = response.data.data.cart_session;
              $cookies.set(
                window.location.hostname.substring(10, 4) + "_cart",
                response.data.data.cart_id,
                "1y"
              );
              this.$store.state.list.cart_id = response.data.data.cart_id;
              this.$store.state.list.thankyou_cart = response.data.data.cart_id;
            }
          } else {
            this.$store.state.list.page_loader = false;
            this.$store.state.list.success_message = "";
            this.$toast.error(response.data.message, {
              position: "top",
              duration: 4000,
            });
            if (
              response.data.cart_session_status == false ||
              response.data.customer_session_status == false
            ) {
              $cookies.set(
                window.location.hostname.substring(10, 4) + "_customer",
                "",
                "1y"
              );
              $cookies.set(
                window.location.hostname.substring(10, 4) + "_cus_sess",
                "",
                "1y"
              );
              this.$store.state.list.customer_id = "";
              this.$store.state.list.customer_session = "";
              $cookies.set(
                window.location.hostname.substring(10, 4) + "_cart",
                "",
                "1y"
              );
              $cookies.set(
                window.location.hostname.substring(10, 4) + "_ct_sess",
                "",
                "1y"
              );
              this.$store.state.list.cart_id = "";
              this.$store.state.list.cart_session = "";
              this.$store.state.list.cart_product = [];
              this.$router.push("/cart");
            }
          }
        })
        .catch((error) => {
          this.$store.state.list.page_loader = false;
          this.$toast.error(
            "Oops there seems to be some Network issue, please try again.",
            {
              position: "top",
              duration: 4000,
            }
          );
        });
    },
  },
};
